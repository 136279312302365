import ModuleManager, {ALL_MODULES} from "./ModuleLoader/ModuleManager";
import ButtonClickHandler from "./Analytics/Handlers/ButtonClickHandler";
import ClickLinkHandler from "./Analytics/Handlers/ClickLinkHandler";
import ClickTracker from "./DocumentTracker/ClickTracker";
import DeviceInstanceIdManager from "./User/DeviceInstanceIdManager";
import HandlersManager from "./Analytics/HandlersManager";
import Logger from "./Logger";
import NavigationLayout from "./Layout/NavigationLayout";
import ScrolledToBeginOfBlockHandler from "./Analytics/Handlers/ScrolledToBeginOfBlockHandler";
import User from "./User/User";
import WebPart from "./Layout/WebPart";

export class App {

    /** @type Window */
    _domWindow;

    /** @type Document */
    _domDocument;

    #logger;

    _user;

    /**
     * @type DeviceInstanceIdManager
     * @private
     */
    _deviceInstanceId = null;
    /**
     * @public
     * @returns {DeviceInstanceIdManager}
     */
    get deviceInstanceId() {
        if (this._deviceInstanceId == null) {
            this._deviceInstanceId = new DeviceInstanceIdManager();
        }

        return this._deviceInstanceId;
    }

    /**
     * @public
     * @returns {User}
     */
    get user() {
        if (!this._user)
            this._user = new User(this.deviceInstanceId);
        return this._user;
    }

    _globals;
    get globals() {
        return Object.assign(this._globals, {
            userId: this.user.anonymousUUID
        });
    }

    constructor(window) {
        this.#logger = new Logger();
        this.#logger.debug("Booting AppBasic");

        this._domWindow = window;
        this._domDocument = window.document;

        if (this._domWindow.ngAppGlobals) {
            this._globals = this._domWindow.ngAppGlobals;
        }

        this._domDocument.addEventListener("DOMContentLoaded", this._boot.bind(this));
    }

    _boot() {
        new NavigationLayout(this._domDocument, this._domWindow).boot();
        this._analyticsManager = new HandlersManager(this._domWindow, this._user);
        this._analyticsManager.addHandlers([
            new ButtonClickHandler(".simpleButton"),
        ]);
        this._analyticsManager.addHandlers([
            new ClickLinkHandler(),
            new ScrolledToBeginOfBlockHandler("[data-location=footer]", "footer"),
        ], 1000);
        (new ModuleManager(this._domWindow, this._analyticsManager)).load(ALL_MODULES);
        this._analyticsManager.startTracking();
        new ClickTracker(this._domWindow, this._user);
        this.bootAdmin();
    }

    /**
     * @returns {boolean}
     */
    bootAdmin() {
        const adminJsAndCssWebpartElement = this._domDocument.querySelector(".webpart[data-webpart-type=admin]");

        if (adminJsAndCssWebpartElement === null) {
            return false;
        }

        new WebPart(
            this._domDocument,
            adminJsAndCssWebpartElement,
        ).load();
    }
}

export const ngApp = new App(window);
